@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
}
.swal2-icon.swal2-info {
  border-color: #ed1c24 !important;
  color: #ed1c24 !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.custom-loader {
  animation: none !important;
  border-width: 0 !important;
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    /* margin-top: 1rem; */
    /* display: block; */
    page-break-before: auto;
    page-break-inside: avoid;
  }
}

@page {
  size: auto;
  /* margin: 0mm; */
}

.font-smooth{ 
    letter-spacing: 0px!important;
    text-rendering:"optimizeLegibility"!important; 
    -webkit-font-smoothing: antialiased!important;
    -moz-osx-font-smoothing: grayscale!important; /* Default: auto */

}
.font-smooth-with-spacing{ 
    letter-spacing: 0px!important;
    text-rendering:"optimizeLegibility"!important; 
    -webkit-font-smoothing: antialiased!important;
    -moz-osx-font-smoothing: grayscale!important; /* Default: auto */

}
.table-text {
  font-size: 12px!important;
}
.table-header-text {
  font-size: 10px!important;
}
.border-mobile{
  border:solid 1px black;
}
.border-TBR{
  border-top:solid 1px black;
  border-bottom:solid 1px black;
  border-right:solid 1px black;
}
.border-RLB{
  border-left:solid 1px black;
  border-bottom:solid 1px black;
  border-right:solid 1px black;
}
.border-RB{
  border-bottom:solid 1px black;
  border-right:solid 1px black;
}
.border-Right{
  border-right:solid 1px black;
}
.border-bottom{
  border-bottom:solid 1px black;
}
.kirtiroadline-headline{
  font-size: 85px!important; /* 96px */
  line-height: 1;
}
.zIndex{
  z-index: 2147483647!important;
}
.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: red!important;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "×";
}